import { bool, number, NumberSchema, object, string, StringSchema } from 'yup';

const emptyStringToUndefined = <V,>(
  value: V,
  originalValue: string,
): V | undefined => {
  return originalValue === '' ? undefined : value;
};

const numberRequired = {
  is: true,
  then: (schema: NumberSchema) =>
    schema
      .required('Required.')
      .typeError('Can only contain numbers.')
      .max(999999999.9999, 'Must not be greater than 999999999.9999'),
};

const yahooMultiplierNumberRequired = {
  is: true,
  then: (schema: NumberSchema) =>
    schema
      .required('Required.')
      .typeError('Can only contain numbers.')
      .max(99.99, 'Must not be greater than 99.99'),
};
const integerRequired = {
  is: true,
  then: (schema: StringSchema) =>
    schema
      .required('Required.')
      .matches(/^[1-9]\d*$/, 'Must be a whole number.'),
};

const requiredWholeNumber = string()
  .required('Required.')
  .matches(/^[1-9]\d*$/, 'Must be a whole number.');

const optionalWholeNumber = string()
  .transform(emptyStringToUndefined)
  .matches(/^([1-9]\d*)?$/, 'Must be a whole number.');

export const packageFormSchema = object().shape({
  name: string()
    .required('Required.')
    .max(32, 'Must not be longer than 32 characters'),
  packageId: string()
    .transform(emptyStringToUndefined)
    .max(50, 'Must not be longer than 50 characters'),
  brand: string().required('Required.'),
  duration: requiredWholeNumber,
  imps: requiredWholeNumber,
  campId: string().transform(emptyStringToUndefined),
  spendGoal: number()
    .transform(emptyStringToUndefined)
    .typeError('Can only contain numbers.')
    .max(999999999.9999, 'Must not be greater than 999999999.9999'),
  clickMinGoal: optionalWholeNumber,
  clickAvgGoal: optionalWholeNumber,
  clickMaxGoal: optionalWholeNumber,
  defaultTargeting: string().required('Required.'),
  applyPlatinumAdditionalTargetingRules: bool().required(),
  facebookFlag: bool().required('Required.'),

  facebookDuration: string()
    .transform(emptyStringToUndefined)
    .when('facebookFlag', integerRequired),
  facebookBid: number()
    .transform(emptyStringToUndefined)
    .when('facebookFlag', numberRequired),
  facebookDailySpend: number()
    .transform(emptyStringToUndefined)
    .when('facebookFlag', numberRequired),
  facebookBidCpm: number()
    .transform(emptyStringToUndefined)
    .when('facebookFlag', numberRequired),
  facebookDailySpendCpm: number()
    .transform(emptyStringToUndefined)
    .when('facebookFlag', numberRequired),
  facebookBillingEvent: string()
    .transform(emptyStringToUndefined)
    .when('facebookFlag', {
      is: true,
      then: (schema) => schema.required('Required.'),
    }),
  facebookPauseCpmFlag: bool().when('facebookFlag', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
  facebookSkipCpmFlag: bool().when('facebookFlag', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
  facebookMTFlag: bool().when('facebookFlag', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
  facebookMTDuration: string()
    .transform(emptyStringToUndefined)
    .when('facebookMTFlag', integerRequired),
  facebookMTBid: number()
    .transform(emptyStringToUndefined)
    .when('facebookMTFlag', numberRequired),
  facebookMTDailySpend: number()
    .transform(emptyStringToUndefined)
    .when('facebookMTFlag', numberRequired),
  facebookMTBillingEvent: string()
    .transform(emptyStringToUndefined)
    .when('facebookMTFlag', {
      is: true,
      then: (schema) => schema.required('Required.'),
    }),
  boostFlag: bool().required('Required.'),

  boostDuration: string()
    .transform(emptyStringToUndefined)
    .when('boostFlag', integerRequired),
  boostDailySpend: number()
    .transform(emptyStringToUndefined)
    .when('boostFlag', numberRequired),
  boostMTFlag: bool().when('boostFlag', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
  boostMTDuration: string()
    .transform(emptyStringToUndefined)
    .when('boostMTFlag', integerRequired),
  boostMTDailySpend: number()
    .transform(emptyStringToUndefined)
    .when('boostMTFlag', numberRequired),
  soldBoostFlag: bool().required('Required.'),
  soldBoostDuration: string()
    .transform(emptyStringToUndefined)
    .when('soldBoostFlag', integerRequired),
  soldBoostDailySpend: number()
    .transform(emptyStringToUndefined)
    .when('soldBoostFlag', numberRequired),
  soldBoostBid: number()
    .transform(emptyStringToUndefined)
    .when('soldBoostFlag', numberRequired),
  soldBoostMTFlag: bool().when('soldBoostFlag', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
  soldBoostMTDuration: string()
    .transform(emptyStringToUndefined)
    .when('soldBoostMTFlag', integerRequired),
  soldBoostMTDailySpend: number()
    .transform(emptyStringToUndefined)
    .when('soldBoostMTFlag', numberRequired),
  soldBoostMTBid: number()
    .transform(emptyStringToUndefined)
    .when('soldBoostMTFlag', numberRequired),
  yahooFlag: bool().required('Required.'),
  yahooDuration: string()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', integerRequired),
  yahooCpcBid: number()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', numberRequired),
  yahooDailySpend: number()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', numberRequired),
  yahooMultiplier1a: number()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', yahooMultiplierNumberRequired),
  yahooMultiplier1b: number()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', yahooMultiplierNumberRequired),
  yahooMultiplier2a: number()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', yahooMultiplierNumberRequired),
  yahooMultiplier2b: number()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', yahooMultiplierNumberRequired),
  yahooMultiplier3a: number()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', yahooMultiplierNumberRequired),
  yahooMultiplier3b: number()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', yahooMultiplierNumberRequired),
  yahooMultiplierGame: number()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', yahooMultiplierNumberRequired),
  yahooCampId: string()
    .transform(emptyStringToUndefined)
    .when('yahooFlag', integerRequired),
  oldYahooDisplayFlag: bool().when('yahooFlag', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
  oldYahooDisplayCampId: string()
    .transform(emptyStringToUndefined)
    .when('oldYahooDisplayFlag', integerRequired),
  yahooDisplayFlag: bool().required('Required.'),
  yahooDisplayDuration: string()
    .transform(emptyStringToUndefined)
    .when('yahooDisplayFlag', integerRequired),
  yahooDisplayCampId: string()
    .transform(emptyStringToUndefined)
    .when('yahooDisplayFlag', integerRequired),
  yahooNativeFlag: bool().required('Required.'),
  yahooNativeDuration: string()
    .transform(emptyStringToUndefined)
    .when('yahooNativeFlag', integerRequired),
  yahooNativeCampId: string()
    .transform(emptyStringToUndefined)
    .when('yahooNativeFlag', integerRequired),
  adformFlag: bool().required('Required.'),
  adformDuration: string()
    .transform(emptyStringToUndefined)
    .when('adformFlag', integerRequired),
  adformCampId: string()
    .transform(emptyStringToUndefined)
    .when(['adformFlag', 'adformCampTypeToUse'], {
      is: (adformFlag: boolean, adformCampTypeToUse: number) =>
        adformFlag && adformCampTypeToUse === 0,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[1-9]\d*$/, 'Must be a whole number.'),
    }),
  adform3pdCampId: string()
    .transform(emptyStringToUndefined)
    .when(['adformFlag', 'adformCampTypeToUse'], {
      is: (adformFlag: boolean, adformCampTypeToUse: number) =>
        adformFlag && adformCampTypeToUse === 1,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[1-9]\d*$/, 'Must be a whole number.'),
    }),
  adformCookielessCampId: string()
    .transform(emptyStringToUndefined)
    .when(['adformFlag', 'adformCampTypeToUse'], {
      is: (adformFlag: boolean, adformCampTypeToUse: number) =>
        adformFlag && adformCampTypeToUse === 2,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[1-9]\d*$/, 'Must be a whole number.'),
    }),
  adformCookieless3pdCampId: string()
    .transform(emptyStringToUndefined)
    .when(['adformFlag', 'adformCampTypeToUse'], {
      is: (adformFlag: boolean, adformCampTypeToUse: number) =>
        adformFlag && adformCampTypeToUse === 3,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[1-9]\d*$/, 'Must be a whole number.'),
    }),
  adformCookieCookielessCampId: string()
    .transform(emptyStringToUndefined)
    .when(['adformFlag', 'adformCampTypeToUse'], {
      is: (adformFlag: boolean, adformCampTypeToUse: number) =>
        adformFlag && adformCampTypeToUse === 4,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[1-9]\d*$/, 'Must be a whole number.'),
    }),
  adformCookieCookieless3pdCampId: string()
    .transform(emptyStringToUndefined)
    .when(['adformFlag', 'adformCampTypeToUse'], {
      is: (adformFlag: boolean, adformCampTypeToUse: number) =>
        adformFlag && adformCampTypeToUse === 5,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[1-9]\d*$/, 'Must be a whole number.'),
    }),
  adformCampTypeToUse: number().when('adformFlag', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
  tradedeskFlag: bool().required('Required.'),
  tradedeskCampTypeToUse: number().when('tradedeskFlag', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
  tradedeskDuration: string()
    .transform(emptyStringToUndefined)
    .when('tradedeskFlag', integerRequired),
  tradedeskCampId: string()
    .transform(emptyStringToUndefined)
    .when(['tradedeskFlag', 'tradedeskCampTypeToUse'], {
      is: (tradedeskFlag: boolean, tradedeskCampTypeToUse: number) =>
        tradedeskFlag && tradedeskCampTypeToUse === 0,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[a-z0-9]*$/, 'Must be letters or digits.')
          .max(10, 'Must not be greater than 10 characters'),
    }),
  tradedeskCookielessCampId: string()
    .transform(emptyStringToUndefined)
    .when(['tradedeskFlag', 'tradedeskCampTypeToUse'], {
      is: (tradedeskFlag: boolean, tradedeskCampTypeToUse: number) =>
        tradedeskFlag && tradedeskCampTypeToUse === 1,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[a-z0-9]*$/, 'Must be letters or digits.')
          .max(10, 'Must not be greater than 10 characters'),
    }),
  tradedeskCookieCookielessCampId: string()
    .transform(emptyStringToUndefined)
    .when(['tradedeskFlag', 'tradedeskCampTypeToUse'], {
      is: (tradedeskFlag: boolean, tradedeskCampTypeToUse: number) =>
        tradedeskFlag && tradedeskCampTypeToUse === 2,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[a-z0-9]*$/, 'Must be letters or digits.')
          .max(10, 'Must not be greater than 10 characters'),
    }),
  tradedesk3pdCampId: string()
    .transform(emptyStringToUndefined)
    .when(['tradedeskFlag', 'tradedeskCampTypeToUse'], {
      is: (tradedeskFlag: boolean, tradedeskCampTypeToUse: number) =>
        tradedeskFlag && tradedeskCampTypeToUse === 3,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[a-z0-9]*$/, 'Must be letters or digits.')
          .max(10, 'Must not be greater than 10 characters'),
    }),
  tradedeskCookieless3pdCampId: string()
    .transform(emptyStringToUndefined)
    .when(['tradedeskFlag', 'tradedeskCampTypeToUse'], {
      is: (tradedeskFlag: boolean, tradedeskCampTypeToUse: number) =>
        tradedeskFlag && tradedeskCampTypeToUse === 4,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[a-z0-9]*$/, 'Must be letters or digits.')
          .max(10, 'Must not be greater than 10 characters'),
    }),
  tradedeskCookieCookieless3pdCampId: string()
    .transform(emptyStringToUndefined)
    .when(['tradedeskFlag', 'tradedeskCampTypeToUse'], {
      is: (tradedeskFlag: boolean, tradedeskCampTypeToUse: number) =>
        tradedeskFlag && tradedeskCampTypeToUse === 5,
      then: (schema: StringSchema) =>
        schema
          .required('Required.')
          .matches(/^[a-z0-9]*$/, 'Must be letters or digits.')
          .max(10, 'Must not be greater than 10 characters'),
    }),
  isArchived: bool().required('Required.'),
});
