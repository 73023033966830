import { H1, Stack } from '@rea-group/construct-kit-core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { fetchJson } from '../../API/fetch';
import ErrorAlert from '../../components/ErrorAlert';
import TargetingModForm from '../../components/targetingMods/TargetingModForm';
import { TargetingMod, TargetingModRequest } from './types';
import { fromTargetingModResponse } from './utils';

const TargetingModContainer = styled.div`
  max-width: 30rem;
  margin: 0 auto;
`;

const createTargetingMod = (
  values: TargetingModRequest,
): Promise<TargetingMod> =>
  fetchJson<TargetingMod>(`/targeting-mods`, {
    method: 'POST',
    body: JSON.stringify(values),
  });

const CreateTargetingMod = (): JSX.Element => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: createTargetingMod,
    onSuccess: async (data) => {
      queryClient.setQueryData(
        ['editTargetingMod', data.id.toString()],
        fromTargetingModResponse(data),
      );
      await queryClient.invalidateQueries({
        queryKey: ['targetingModsData'],
      });
      navigate('/targeting-mods');
    },
  });

  return (
    <TargetingModContainer>
      <Stack>
        <H1>Create Target</H1>
        {isError && <ErrorAlert error={error} />}
        <TargetingModForm onSave={mutate} isLoading={isPending} />
      </Stack>
    </TargetingModContainer>
  );
};

export default CreateTargetingMod;
