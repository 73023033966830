import moment from 'moment';
import { useState } from 'react';
import { SingleDatePicker as ReactSingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import { Nullable } from '../../types/Nullable';
import { DatePickerStyled } from './DatePickerStyled';
import ReactDatesDefaultStyles from './ReactDatesDefaultStyles';

interface SingleDatePickerProps {
  placeHolder: string;
  date: Nullable<moment.Moment>;
  onDateChange: (arg: Nullable<moment.Moment>) => void;
}

const SingleDatePicker = ({
  placeHolder,
  date,
  onDateChange,
}: SingleDatePickerProps): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <ReactDatesDefaultStyles>
      <DatePickerStyled>
        <ReactSingleDatePicker
          id="date"
          focused={focused}
          date={date}
          placeholder={placeHolder}
          displayFormat={() => 'DD/MM/YYYY'}
          onDateChange={onDateChange}
          isOutsideRange={() => false}
          hideKeyboardShortcutsPanel={true}
          onFocusChange={({ focused }) => setFocused(focused)}
          numberOfMonths={1}
          verticalSpacing={10}
        />
      </DatePickerStyled>
    </ReactDatesDefaultStyles>
  );
};

export default SingleDatePicker;
