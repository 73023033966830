import {
  Inline,
  paletteHelper,
  Text,
  Tooltip,
} from '@rea-group/construct-kit-core';
import styled from 'styled-components';

const InlineError = styled(Inline)`
  color: ${paletteHelper('textError')};
`;

const StyledTooltip = styled(Tooltip)`
  .elementPopoverOverlay {
    max-width: 100%;
  }

  .contentWrapper {
    max-width: none;
    overflow-x: scroll;
  }

  .tooltipIcon {
    color: ${paletteHelper('textError')};
  }
`;

interface ErrorLinkMessageProps {
  messageText: string;
}

const ErrorLinkMessage = ({
  messageText,
}: ErrorLinkMessageProps): JSX.Element => (
  <InlineError grow={false} gap="twoExtraSmall">
    <Text as="span">Error</Text>
    <StyledTooltip
      sizeVariant="medium"
      dangerouslySetClassNames={{
        elementPopoverClassNames: {
          overlay: 'elementPopoverOverlay',
        },
        contentWrapper: 'contentWrapper',
        icon: 'tooltipIcon',
      }}
    >
      <pre>{messageText}</pre>
    </StyledTooltip>
  </InlineError>
);

export default ErrorLinkMessage;
