import { Link } from '@rea-group/construct-kit-core';
import { ValidationErrorBooking } from '../../pages/UploadBookingCampaign';
import BookingsModal from './BookingsModal';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import { formatDate } from '../../utils/formatDate';

interface StartDateErrorModalProps {
  bookings: ValidationErrorBooking[];
}

const StartDateErrorModal = ({
  bookings,
}: StartDateErrorModalProps): JSX.Element => {
  return (
    <BookingsModal heading="Past Start Date">
      <Table>
        <TableHeader>
          <TableHeaderColumn>Booking ID</TableHeaderColumn>
          <TableHeaderColumn>Listing ID</TableHeaderColumn>
          <TableHeaderColumn>Start Date</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {bookings.length === 0 ? (
            <EmptyTableBody />
          ) : (
            bookings.map(({ rowNumber, bookingId, listingId, startDate }) => (
              <TableRow key={rowNumber}>
                <TableContentColumn>{bookingId}</TableContentColumn>
                <TableContentColumn>
                  <Link
                    href={`https://www.realestate.com.au/${listingId}`}
                    target="_blank"
                  >
                    {listingId}
                  </Link>
                </TableContentColumn>
                <TableContentColumn>{formatDate(startDate)}</TableContentColumn>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </BookingsModal>
  );
};

export default StartDateErrorModal;
