import { Link } from '@rea-group/construct-kit-core';
import { ReactNode } from 'react';

interface ExternalLinkProps {
  href: string;
  children: ReactNode;
}

const ExternalLink = ({ href, children }: ExternalLinkProps): JSX.Element => (
  <Link href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </Link>
);

export default ExternalLink;
