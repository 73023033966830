import { isPresent } from '../utils/helpers';

const guid = (): string => {
  const s4 = (): string => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
};

const createLockeLoginUrl = (): string => {
  const stateGuid = guid();

  window.sessionStorage.setItem('stateGuid', stateGuid);

  const state = {
    redirectUrl: `${window.location.pathname}${window.location.search}`,
    guid: stateGuid,
  };

  return `${process.env.LOCKE_LOGIN_URL}?client_id=${
    process.env.LOCKE_CLIENT_ID
  }&response_type=code&audience=resi&scope=openid%20profile%20email%20phone%20offline_access&connection=okta&redirect_uri=${
    process.env.LOCKE_REDIRECT_URI
  }&state=${window.btoa(JSON.stringify(state))}`;
};

const TRANSACTION_ID_HEADER_NAME = 'x-transaction-id';

interface ApiErrorResponse {
  type: string;
  message: string;
}

const isErrorResponse = (
  responseJson: unknown,
): responseJson is ApiErrorResponse => {
  return (
    typeof responseJson === 'object' &&
    isPresent(responseJson) &&
    'type' in responseJson &&
    typeof responseJson.type === 'string' &&
    'message' in responseJson &&
    typeof responseJson.message === 'string'
  );
};

export class ApiError extends Error {
  readonly status: number;
  readonly type: string;

  constructor(message: string, status: number, type: string) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
    this.type = type;
  }
}

const handleErrorResponse = async (response: Response): Promise<void> => {
  const responseJson: unknown = await response.json();

  if (!isErrorResponse(responseJson)) {
    throw new Error('An error occurred.');
  }

  const { type, message } = responseJson;
  const { status } = response;

  if (status === 401 && type === 'oktaUnauthorizedError') {
    window.location.replace(createLockeLoginUrl());
  }

  const messageWithTransactionId = `${message} with transactionId: ${response.headers.get(
    TRANSACTION_ID_HEADER_NAME,
  )}`;

  throw new ApiError(messageWithTransactionId, status, type);
};

const getFetchResponse = async (
  url: string,
  options?: RequestInit,
): Promise<Response> => {
  const response = await fetch(`${process.env.API_ROOT}${url}`, {
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    ...options,
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response;
};

export const fetchJson = async <T>(
  url: string,
  options?: RequestInit,
): Promise<T> => {
  const response = await getFetchResponse(url, options);

  return response.json() as Promise<T>;
};

export const requestVoid = async (
  url: string,
  options?: RequestInit,
): Promise<void> => void (await getFetchResponse(url, { ...options }));

export const requestDelete = (
  url: string,
  options?: RequestInit,
): Promise<void> => requestVoid(url, { ...options, method: 'DELETE' });

export const postVoid = (url: string, options?: RequestInit): Promise<void> =>
  requestVoid(url, { ...options, method: 'POST' });

export const putVoid = (url: string, options?: RequestInit): Promise<void> =>
  requestVoid(url, { ...options, method: 'PUT' });
