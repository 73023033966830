import {
  ArrowLeftTwoLg,
  ArrowRightTwoLg,
  Button,
  Inline,
  P,
} from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { useCallback, useState } from 'react';

const StyledInline = styled(Inline)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 0 3rem;
  height: 3.6rem;
`;
const PaginationInline = styled(Inline)`
  justify-content: flex-end;
`;

interface PaginationProps {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  onPreviousPageButtonClick: () => void;
  onNextPageButtonClick: () => void;
}

export interface PaginationContext {
  pageNumber: number;
  onPreviousPageButtonClick: () => void;
  onNextPageButtonClick: () => void;
  resetPagination: () => void;
}

export const usePagination = (): PaginationContext => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onPreviousPageButtonClick = useCallback(
    (): void => setPageNumber((pageNumber) => pageNumber - 1),
    [],
  );

  const onNextPageButtonClick = useCallback(
    (): void => setPageNumber((pageNumber) => pageNumber + 1),
    [],
  );

  const resetPagination = useCallback((): void => {
    setPageNumber(1);
  }, []);

  return {
    pageNumber,
    onPreviousPageButtonClick,
    onNextPageButtonClick,
    resetPagination,
  };
};

const Pagination = ({
  pageNumber,
  pageSize,
  totalCount,
  onPreviousPageButtonClick,
  onNextPageButtonClick,
}: PaginationProps): JSX.Element => {
  const itemNumberStart = (pageNumber - 1) * pageSize + 1;
  const itemNumberEnd = Math.min(pageNumber * pageSize, totalCount);
  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <StyledInline>
      <P>
        {itemNumberStart} - {itemNumberEnd} of {totalCount} items
      </P>
      <PaginationInline grow={false} gap="small">
        <P>
          {pageNumber} of {totalPages} pages
        </P>
        <Button
          compact={true}
          sizeVariant="small"
          icon={<ArrowLeftTwoLg />}
          hideLabel={true}
          variant="outline"
          disabled={pageNumber <= 1}
          onClick={() => onPreviousPageButtonClick()}
        >
          Previous Page
        </Button>
        <Button
          compact={true}
          sizeVariant="small"
          icon={<ArrowRightTwoLg />}
          hideLabel={true}
          variant="outline"
          disabled={pageNumber >= totalPages}
          onClick={() => onNextPageButtonClick()}
        >
          Next Page
        </Button>
      </PaginationInline>
    </StyledInline>
  );
};

export default Pagination;
