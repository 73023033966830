import 'react-dates/initialize';
import ReactDatesDefaultStyles from './ReactDatesDefaultStyles';
import {
  DateRangePicker as ReactDateRangePicker,
  FocusedInputShape,
} from 'react-dates';
import moment from 'moment';
import React, { useState } from 'react';
import { DatePickerStyled } from './DatePickerStyled';
import { Nullable } from '../../types/Nullable';

export interface DateRange {
  startDate: Nullable<moment.Moment>;
  endDate: Nullable<moment.Moment>;
}

interface DateRangePickerProps {
  dateRange: DateRange;
  setDateRange: (arg: DateRange) => void;
}

const DateRangePicker = ({
  dateRange,
  setDateRange,
}: DateRangePickerProps): JSX.Element => {
  const [focusInput, setFocusInput] = useState<FocusedInputShape | null>(null);

  return (
    <ReactDatesDefaultStyles>
      <DatePickerStyled>
        <ReactDateRangePicker
          startDate={dateRange.startDate}
          startDateId="start-date"
          endDate={dateRange.endDate}
          endDateId="end-date"
          onDatesChange={setDateRange}
          focusedInput={focusInput}
          onFocusChange={(value) => {
            setFocusInput(value);
          }}
          displayFormat={() => 'DD/MM/YYYY'}
          customArrowIcon="to"
          isOutsideRange={() => false}
          hideKeyboardShortcutsPanel={true}
          verticalSpacing={10}
        />
      </DatePickerStyled>
    </ReactDatesDefaultStyles>
  );
};

export default DateRangePicker;
