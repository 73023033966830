import {
  Button,
  Dialog,
  H2,
  Inline,
  Modal,
  Stack,
} from '@rea-group/construct-kit-core';
import { getAppRoot } from '../utils/getAppRoot';
import ErrorAlert from '../components/ErrorAlert';
import styled from 'styled-components';
import { ButtonLoadingSpinner } from './LoadingSpinner';

const InlineEqualSize = styled(Inline)`
  & > * {
    flex: 1 1 0;
  }
`;

export interface ConfirmModalProps {
  headerText: string;
  okButtonText: string;
  isOpen: boolean;
  isError: boolean;
  error: unknown;
  isOkButtonDisabled: boolean;
  onOk: () => void;
  onCancel: () => void;
  onClose: () => void;
}

const ConfirmModal = ({
  headerText,
  okButtonText,
  isOpen,
  isError,
  error,
  isOkButtonDisabled,
  onOk,
  onCancel,
  onClose,
}: ConfirmModalProps): JSX.Element => {
  return (
    <Modal opened={isOpen} onRequestClose={onClose} getAppElement={getAppRoot}>
      <Dialog onClose={onClose}>
        <Stack gap="large" inset="0 medium medium">
          <H2>
            <center>{headerText}</center>
          </H2>
          {isError && <ErrorAlert error={error} />}
          <InlineEqualSize gap="medium" justifyContent="space-between">
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onOk}
              disabled={isOkButtonDisabled}
              iconPlacement="right"
              icon={isOkButtonDisabled ? <ButtonLoadingSpinner /> : undefined}
            >
              {isOkButtonDisabled ? 'Loading' : okButtonText}
            </Button>
          </InlineEqualSize>
        </Stack>
      </Dialog>
    </Modal>
  );
};

export default ConfirmModal;
