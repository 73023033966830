import { ReactNode } from 'react';
import Status from '../Status';
import { formatDateTime } from '../../utils/formatDate';
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableContentColumn,
  EmptyTableBody,
} from '../Table/Table';
import Panel from './Panel';

interface EmailsData {
  status: string;
  count: number;
  mostRecent: string | null;
}

interface EmailsPanelProps {
  emailsData: EmailsData[];
  heading: string;
  buttonText: string;
  modalContents: ReactNode;
}

const EmailsPanel = ({
  emailsData,
  heading,
  buttonText,
  modalContents,
}: EmailsPanelProps): JSX.Element => {
  return (
    <Panel
      heading={heading}
      buttonText={buttonText}
      modalContents={modalContents}
    >
      <Table>
        <TableHeader>
          <TableHeaderColumn>Status</TableHeaderColumn>
          <TableHeaderColumn>Count</TableHeaderColumn>
          <TableHeaderColumn>Most Recent</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {emailsData.length === 0 ? (
            <EmptyTableBody />
          ) : (
            emailsData.map(({ status, count, mostRecent }) => (
              <TableRow key={status}>
                <TableContentColumn>
                  <Status status={status} />
                </TableContentColumn>
                <TableContentColumn>{count}</TableContentColumn>
                <TableContentColumn>
                  {formatDateTime(mostRecent)}
                </TableContentColumn>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Panel>
  );
};

export default EmailsPanel;
