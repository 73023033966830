import { useEffect, useState } from 'react';
import {
  Stack,
  H3,
  H4,
  P,
  Card,
  Divider,
  paletteHelper,
  spacingHelper,
  Strong,
  Inline,
  Button,
  Alert,
  Link,
} from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import {
  CreateCampaignResponse,
  ErrorsOrWarningsOrNull,
  PropertyRegionWarning,
  PropertyRegionWarningBooking,
  ValidationError,
  ValidationWarning,
  ValidationWarnings,
} from '../pages/UploadBookingCampaign';
import { isPresent } from '../utils/helpers';
import ProcessBookingCampaignConfirmModel from './ProcessBookingCampaignConfirmModel';
import PropertyTypesWarningPanel from './ProcessBookingCampaignModals/PropertyTypesWarningPanel';
import { match, P as Pa } from 'ts-pattern';
import ErrorsOrWarningsViewListingModel from './ProcessBookingCampaignModals/ErrorsOrWarningsViewListingModal';
import PropertyRegionsWarningPanel from './ProcessBookingCampaignModals/PropertyRegionWarningPanel';

const StyledStack = styled(Stack)`
  height: 25rem;
`;

const StyledP = styled(P)`
  color: ${paletteHelper('textSecondary')};
  text-align: center;
  max-width: 30.875rem;
`;

const StyledDivider = styled(Divider)`
  margin: ${spacingHelper('0 0 medium')};
`;

const ViewBookingsButton = styled(Button)`
  color: ${({ theme }) => theme.palette.textPrimary};
`;

export type ValidationErrorOrWarning = ValidationError | ValidationWarning;

const updateWithNewBooking = (
  currentBookings: UpdateBooking[],
  newBooking: UpdateBookingPropertyType | UpdateBookingRegion,
): UpdateBooking[] => {
  if (currentBookings.some((a) => a.bookingId === newBooking.bookingId)) {
    currentBookings.map((booking) =>
      booking.bookingId === newBooking.bookingId
        ? { ...booking, ...newBooking }
        : booking,
    );
  }
  return [...currentBookings, newBooking];
};

interface ValidationWarningsWithRegionWarning extends ValidationWarnings {
  propertyRegionWarning: PropertyRegionWarning;
}

const isPropertyRegionWarning = (
  errorsOrWarnings: ErrorsOrWarningsOrNull | undefined,
): errorsOrWarnings is ValidationWarningsWithRegionWarning => {
  return (
    isPresent(errorsOrWarnings) &&
    errorsOrWarnings.type === 'warning' &&
    isPresent(errorsOrWarnings.propertyRegionWarning)
  );
};

export interface Region {
  id: number;
  region: string;
  state: string;
}
export interface UpdateBookingPropertyTypeAndRegion {
  bookingId: string;
  propertyType: string;
  region: Region;
}

export interface UpdateBookingPropertyType {
  bookingId: string;
  propertyType: string;
}

export interface UpdateBookingRegion {
  bookingId: string;
  region: Region;
}

export type UpdateBooking =
  | UpdateBookingPropertyTypeAndRegion
  | UpdateBookingPropertyType
  | UpdateBookingRegion;

export interface UpdateBookingRequest {
  bookingsToUpdate: UpdateBooking[];
}

interface ProcessBookingCampaignPanelProps {
  bookingCampaignData: CreateCampaignResponse | undefined;
}

const ProcessBookingCampaignPanel = ({
  bookingCampaignData,
}: ProcessBookingCampaignPanelProps): JSX.Element => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [modalValidation, setModalValidation] =
    useState<ValidationErrorOrWarning>();
  const [bookingsToUpdate, setBookingsToUpdate] = useState<UpdateBooking[]>([]);
  const [
    isAllMissingRegionBookingHandled,
    setIsAllMissingRegionBookingHandled,
  ] = useState(false);

  const onBookingUpdateHandler = (
    updateBooking: UpdateBookingPropertyType | UpdateBookingRegion,
  ): void => {
    setBookingsToUpdate((bookingsToUpdate) =>
      updateWithNewBooking(bookingsToUpdate, updateBooking),
    );
  };

  useEffect(() => {
    const propertyRegionWarnings = bookingCampaignData?.errorsOrWarnings;

    if (!isPropertyRegionWarning(propertyRegionWarnings)) {
      return;
    }

    const { bookings } = propertyRegionWarnings.propertyRegionWarning;

    const bookingsToUpdate = bookings
      .map(({ bookingId, suggestedRegion }: PropertyRegionWarningBooking) => {
        if (!isPresent(suggestedRegion)) {
          return undefined;
        }

        return {
          bookingId,
          region: suggestedRegion,
        };
      })
      .filter(isPresent);

    setBookingsToUpdate(bookingsToUpdate);
  }, [bookingCampaignData]);

  useEffect(() => {
    const warnings = bookingCampaignData?.errorsOrWarnings;

    if (!isPropertyRegionWarning(warnings)) {
      setIsAllMissingRegionBookingHandled(true);
      return;
    }

    const { bookings } = warnings.propertyRegionWarning;
    const bookingIds = bookings.map((booking) => booking.bookingId);
    const bookingsToUpdateIds = bookingsToUpdate.map(
      (bookingToUpdate) => bookingToUpdate.bookingId,
    );
    const isAllMissingRegionBookingHandled = bookingIds.every((bookingId) =>
      bookingsToUpdateIds.includes(bookingId),
    );

    setIsAllMissingRegionBookingHandled(isAllMissingRegionBookingHandled);
  }, [bookingCampaignData, bookingsToUpdate]);

  return (
    <Card>
      {isPresent(bookingCampaignData) ? (
        <Stack inset="large medium" gap="medium">
          <Stack gap="extraSmall">
            <H3>Upload bookings detail</H3>
            <H4 variant="body01">
              There are {bookingCampaignData.bookingCount} bookings in this
              upload to be processed.
            </H4>
          </Stack>
          <Stack gap="large">
            <StyledDivider />
            <Stack gap="extraLarge">
              <Stack gap="twoExtraLarge">
                {match(bookingCampaignData.errorsOrWarnings)
                  .with({ type: 'error' }, ({ errors }) => (
                    <Stack gap="extraSmall">
                      {errors.map((error) => {
                        const { validationType, message } = error;
                        return (
                          <Alert key={validationType} variant="error">
                            {`${message} `}
                            <ViewBookingsButton
                              variant="link-inline"
                              onClick={() => {
                                setModalValidation(error);
                              }}
                            >
                              View bookings
                            </ViewBookingsButton>
                          </Alert>
                        );
                      })}
                    </Stack>
                  ))
                  .with(
                    { type: 'warning' },
                    ({
                      propertyTypeWarning,
                      propertyRegionWarning: propertyRegionWarnings,
                      warnings,
                    }) => (
                      <Stack gap="extraSmall">
                        {warnings.map((warning) => {
                          const { validationType, message } = warning;
                          return (
                            <Alert key={validationType} variant="warning">
                              {`${message} `}
                              <ViewBookingsButton
                                variant="link-inline"
                                onClick={() => {
                                  setModalValidation(warning);
                                }}
                              >
                                View bookings
                              </ViewBookingsButton>
                            </Alert>
                          );
                        })}
                        {(isPresent(propertyTypeWarning) ||
                          isPresent(propertyRegionWarnings)) && (
                          <>
                            <Stack gap="extraSmall" inset="large 0 0">
                              <H4>Modify Bookings</H4>
                              <P>
                                Review and make any modifications to the
                                bookings before processing the campaign
                              </P>
                            </Stack>
                            {isPresent(propertyTypeWarning) && (
                              <PropertyTypesWarningPanel
                                bookings={propertyTypeWarning.bookings}
                                onPropertyTypeChange={onBookingUpdateHandler}
                              />
                            )}
                            {isPresent(propertyRegionWarnings) && (
                              <PropertyRegionsWarningPanel
                                bookings={propertyRegionWarnings.bookings}
                                onRegionChange={onBookingUpdateHandler}
                              />
                            )}
                          </>
                        )}
                      </Stack>
                    ),
                  )
                  .with(Pa.nullish, () => null)
                  .exhaustive()}
                <ErrorsOrWarningsViewListingModel
                  modalValidation={modalValidation}
                  onClose={setModalValidation}
                />
                {bookingCampaignData?.errorsOrWarnings?.type === 'error' && (
                  <P>
                    <Strong>
                      As there were errors when uploading the booking campaign
                      will need to edit your file before trying again.
                    </Strong>
                  </P>
                )}
              </Stack>
              <Inline gap="medium" grow={false}>
                <Link
                  as={RouterLink}
                  to="/"
                  variant="button-outline"
                  sizeVariant="medium"
                >
                  Cancel
                </Link>
                <Button
                  variant="primary"
                  sizeVariant="medium"
                  onClick={() => {
                    setIsConfirmModalOpen(true);
                  }}
                  disabled={
                    bookingCampaignData?.errorsOrWarnings?.type === 'error' ||
                    !isAllMissingRegionBookingHandled
                  }
                >
                  Process now
                </Button>
                <ProcessBookingCampaignConfirmModel
                  isOpen={isConfirmModalOpen}
                  onCancel={() => {
                    setIsConfirmModalOpen(false);
                  }}
                  onClose={() => {
                    setIsConfirmModalOpen(false);
                  }}
                  bookingsToUpdate={bookingsToUpdate}
                />
              </Inline>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <StyledStack gap="small" alignItems="center" justifyContent="center">
          <H3>No bookings.</H3>
          <StyledP>
            Select bookings csv file to upload bookings. All information will be
            displayed here.
          </StyledP>
        </StyledStack>
      )}
    </Card>
  );
};

export default ProcessBookingCampaignPanel;
