import TotalCampaignReportingPanel from './TotalCampaignReportingPanel';

export interface MediamathReportingCampaignFlatData {
  id: number;
  campaignId: number;
  campaignName: string;
  reportDate: string;
  startDate: string;
  endDate: string;
  impressions: number | null;
  clicks: number | null;
  spend: number | null;
  budget: number | null;
}

export interface BookingDataMmTotalCampaignReportingPanelProps {
  data: MediamathReportingCampaignFlatData[];
}

const BookingDataMmTotalCampaignReportingPanel = ({
  data,
}: BookingDataMmTotalCampaignReportingPanelProps): JSX.Element => (
  <TotalCampaignReportingPanel
    heading="MM Total Campaign Reporting"
    data={data}
  />
);
export default BookingDataMmTotalCampaignReportingPanel;
