import { Stack } from '@rea-group/construct-kit-core';
import { useMutation } from '@tanstack/react-query';
import { putVoid } from '../../API/fetch';
import ConfirmModal from '../ConfirmModal';
import ErrorAlert from '../ErrorAlert';

export enum Action {
  Cancel = 'cancel',
  Pause = 'pause',
  Resume = 'resume',
}

export interface UpdateTrackerStatusProps {
  bookingId: string;
  opened: boolean;
  action: Action;
  onRequestClose: () => void;
}

const updateTrackerStatusPromise =
  (bookingId: string, action: Action) => (): Promise<void> =>
    putVoid(`/booking-trackers/${bookingId}/${action}`);

const BookingTrackerUpdateModal = ({
  bookingId,
  opened,
  onRequestClose,
  action,
}: UpdateTrackerStatusProps): JSX.Element => {
  const updateTrackerStatusMutation = useMutation({
    mutationFn: updateTrackerStatusPromise(bookingId ?? '', action),
    onSuccess: () => {
      onRequestClose();
    },
  });

  return (
    <Stack>
      {updateTrackerStatusMutation.isError && !opened && (
        <ErrorAlert error={updateTrackerStatusMutation.error} />
      )}
      <ConfirmModal
        headerText={`Are you sure you want to ${action} this booking?`}
        okButtonText="OK"
        isOpen={opened}
        isError={updateTrackerStatusMutation.isError}
        error={updateTrackerStatusMutation.error}
        isOkButtonDisabled={updateTrackerStatusMutation.isPending}
        onClose={() => {
          updateTrackerStatusMutation.reset();
          onRequestClose();
        }}
        onOk={() => {
          updateTrackerStatusMutation.mutate();
        }}
        onCancel={() => {
          updateTrackerStatusMutation.reset();
          onRequestClose();
        }}
      />
    </Stack>
  );
};

export default BookingTrackerUpdateModal;
