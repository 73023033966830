import {
  Button,
  Dialog,
  Inline,
  Modal,
  Stack,
  Text,
  spacingHelper,
} from '@rea-group/construct-kit-core';
import { useState } from 'react';
import styled from 'styled-components';
import { formatDateTime } from '../../utils/formatDate';
import { getAppRoot } from '../../utils/getAppRoot';
import { isPresent } from '../../utils/helpers';
import Status from '../Status';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import OptimizationLogModal, {
  OptimizationLogModalProps,
} from './OptimizationLogModal';
import Panel from './Panel';

const StyledDialog = styled(Dialog)`
  min-width: 75rem;
  min-height: 30rem;
  padding: ${spacingHelper('0 medium medium')};
`;

const StyledStack = styled(Stack).attrs({
  gap: 'twoExtraSmall',
  alignItems: 'center',
})``;

const StatusLabel = styled(Text).attrs({
  variant: 'subtitle02',
})``;

const StatusCount = styled(Text).attrs({
  variant: 'title01',
})``;

export interface OptimizationData {
  id: string;
  action: string;
  system: string;
  status: string;
  count: number;
  mostRecent: string | null;
}

interface OptimizationStatusCounts {
  waiting: number;
  processing: number;
  queued: number;
  done: number;
  error: number;
}

interface OptimizationProps {
  optimizationData: OptimizationData[];
  heading: string;
  optimizationStatusCounts?: OptimizationStatusCounts;
  date?: string;
}

const OptimizationPanel = ({
  optimizationData,
  heading,
  optimizationStatusCounts,
  date,
}: OptimizationProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState<OptimizationLogModalProps>();

  return (
    <Panel heading={heading}>
      {isPresent(optimizationStatusCounts) && (
        <Inline data-testid="optimization-status-counts">
          <StyledStack>
            <StatusLabel>Waiting</StatusLabel>
            <StatusCount>{optimizationStatusCounts.waiting}</StatusCount>
          </StyledStack>
          <StyledStack>
            <StatusLabel>Processing</StatusLabel>
            <StatusCount>{optimizationStatusCounts.processing}</StatusCount>
          </StyledStack>
          <StyledStack>
            <StatusLabel>Queued</StatusLabel>
            <StatusCount>{optimizationStatusCounts.queued}</StatusCount>
          </StyledStack>
          <StyledStack>
            <StatusLabel>Done</StatusLabel>
            <StatusCount>{optimizationStatusCounts.done}</StatusCount>
          </StyledStack>
          <StyledStack>
            <StatusLabel>Error</StatusLabel>
            <StatusCount>{optimizationStatusCounts.error}</StatusCount>
          </StyledStack>
        </Inline>
      )}
      <Table>
        <TableHeader>
          <TableHeaderColumn>Action</TableHeaderColumn>
          <TableHeaderColumn>System</TableHeaderColumn>
          <TableHeaderColumn>Status</TableHeaderColumn>
          <TableHeaderColumn>Count</TableHeaderColumn>
          <TableHeaderColumn>Most Recent</TableHeaderColumn>
          <TableHeaderColumn>Log</TableHeaderColumn>
        </TableHeader>
        <TableBody
          emptyStateText={'There are no optimisations scheduled for today'}
        >
          {optimizationData.length === 0 ? (
            <EmptyTableBody />
          ) : (
            optimizationData.map(
              ({ id, action, system, status, count, mostRecent }) => (
                <TableRow key={id}>
                  <TableContentColumn>{action}</TableContentColumn>
                  <TableContentColumn>{system}</TableContentColumn>
                  <TableContentColumn>
                    <Status status={status} />
                  </TableContentColumn>
                  <TableContentColumn>{count}</TableContentColumn>
                  <TableContentColumn>
                    {formatDateTime(mostRecent)}
                  </TableContentColumn>
                  <TableContentColumn>
                    <Button
                      variant="link-primary"
                      onClick={() => {
                        setModalProps({ action, system, status, date });
                        setIsModalOpen(true);
                      }}
                    >
                      Link
                    </Button>
                  </TableContentColumn>
                </TableRow>
              ),
            )
          )}
        </TableBody>
      </Table>
      <Modal
        opened={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          setModalProps(undefined);
        }}
        getAppElement={getAppRoot}
      >
        <StyledDialog onClose={() => setIsModalOpen(false)} maxWidth="100rem">
          {modalProps && <OptimizationLogModal {...modalProps} />}
        </StyledDialog>
      </Modal>
    </Panel>
  );
};

export default OptimizationPanel;
