import styled, { css } from 'styled-components';
import { ReactNode } from 'react';
import {
  paletteHelper,
  spacingHelper,
  typographyHelper,
} from '@rea-group/construct-kit-core';
import { isPresent } from '../../utils/helpers';

interface StyledTableProps {
  children: ReactNode;
  $isStickyHeader?: boolean;
}

const StyledTable = styled.table<StyledTableProps>`
  width: 100%;
  border-radius: ${({ theme }) =>
    `${theme.border.radius.large} ${theme.border.radius.large} 0 0`};
  overflow: ${({ $isStickyHeader }) =>
    $isStickyHeader ? 'visible' : 'hidden'};
`;

interface TableProps {
  children: ReactNode;
  isStickyHeader?: boolean;
}

const Table = ({ children, isStickyHeader }: TableProps): JSX.Element => {
  return <StyledTable $isStickyHeader={isStickyHeader}>{children}</StyledTable>;
};

interface StyledTableHeaderProps {
  $isStickyHeader?: boolean;
  children: ReactNode;
}

const StyledThead = styled.thead<StyledTableHeaderProps>`
  ${({ $isStickyHeader }) =>
    $isStickyHeader &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `}
`;

interface TableHeaderProps {
  isStickyHeader?: boolean;
  children: ReactNode;
}

const TableHeader = ({
  children,
  isStickyHeader,
}: TableHeaderProps): JSX.Element => {
  return (
    <StyledThead $isStickyHeader={isStickyHeader}>
      <tr>{children}</tr>
    </StyledThead>
  );
};

interface StyledThProps {
  $noWrap: boolean;
  className?: string;
  $isCompact?: boolean;
  $hasMinWidth?: boolean;
  freeze?: string;
}

const StyledTh = styled.th<StyledThProps>`
  ${typographyHelper('subtitle02')}
  text-align: left;
  padding: ${({ $isCompact }) =>
    $isCompact
      ? spacingHelper('small extraSmall')
      : spacingHelper('small medium')};
  border-bottom: 1px solid ${paletteHelper('borderSecondary')};
  min-width: ${({ $isCompact, $hasMinWidth }) =>
    $hasMinWidth || !$isCompact ? '6.594rem' : 'auto'};
  background-color: ${paletteHelper('backgroundSecondaryBase')};
  ${(props) =>
    props.$noWrap &&
    css`
      white-space: nowrap;
    `};
  ${(props) =>
    isPresent(props.freeze) &&
    css`
      ${props.freeze}
    `};
`;

interface TableHeaderColumnProps {
  noWrap?: boolean;
  children?: ReactNode;
  className?: string;
  isCompact?: boolean;
  hasMinWidth?: boolean;
  freeze?: string;
}

const TableHeaderColumn = ({
  noWrap = true,
  className,
  children,
  isCompact,
  hasMinWidth,
  freeze,
}: TableHeaderColumnProps): JSX.Element => {
  return (
    <StyledTh
      $noWrap={noWrap}
      className={className}
      $isCompact={isCompact}
      $hasMinWidth={hasMinWidth}
      freeze={freeze}
    >
      {children}
    </StyledTh>
  );
};

const StyledTBody = styled.tbody`
  border-bottom: 1px solid ${paletteHelper('borderSecondary')};
`;

export const EmptyTableBody = (): JSX.Element => {
  return (
    <TableRow>
      <TableContentColumn>No records found.</TableContentColumn>
    </TableRow>
  );
};

interface TableBodyProps {
  children: ReactNode;
  emptyStateText?: string;
}

const TableBody = ({ children }: TableBodyProps): JSX.Element => {
  return <StyledTBody>{children}</StyledTBody>;
};

interface TableRowProps {
  children: ReactNode;
}

const TableRow = ({ children }: TableRowProps): JSX.Element => {
  return <tr>{children}</tr>;
};

interface StyledTdProps {
  $noWrap: boolean;
  className?: string;
  $isCompact?: boolean;
  freeze?: string;
}

const StyledTd = styled.td<StyledTdProps>`
  ${typographyHelper('body02')}
  border-bottom: 1px solid ${paletteHelper('borderSecondary')};
  padding: ${({ $isCompact }) =>
    $isCompact
      ? spacingHelper('twoExtraSmall medium')
      : spacingHelper('small medium')};
  vertical-align: middle;
  ${(props) =>
    props.$noWrap &&
    css`
      white-space: nowrap;
    `};
  ${(props) =>
    isPresent(props.freeze) &&
    css`
      ${props.freeze}
    `};

  tr:nth-child(odd) & {
    background-color: ${paletteHelper('backgroundPrimary')};
  }

  tr:nth-child(even) & {
    background-color: #fbfbfb;
  }
`;

interface TableContentColumnProps {
  noWrap?: boolean;
  children: ReactNode;
  className?: string;
  isCompact?: boolean;
  onColumnClick?: () => void;
  freeze?: string;
}

const TableContentColumn = ({
  noWrap = true,
  className,
  children,
  onColumnClick,
  isCompact,
  freeze,
}: TableContentColumnProps): JSX.Element => {
  return (
    <StyledTd
      $noWrap={noWrap}
      className={className}
      onClick={onColumnClick}
      $isCompact={isCompact}
      freeze={freeze}
    >
      {children}
    </StyledTd>
  );
};

export {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableContentColumn,
};
