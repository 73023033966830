import { P, Stack } from '@rea-group/construct-kit-core';
import Panel from './Panel';
import Chart, { Scales } from '../Chart';
import { formatDateTime } from '../../utils/formatDate';

type StringOrNumber = string | number;

interface GraphPlatformData {
  impressions: StringOrNumber[];
  clicks: StringOrNumber[];
  count: number[];
}

interface ChartData {
  lastUpdated?: string;
  labels: string[];
  facebook: GraphPlatformData;
  yahoo: GraphPlatformData;
  adform: GraphPlatformData;
  tradedesk: GraphPlatformData;
  yahooNative: GraphPlatformData;
}

interface ChartPanelProps {
  chartData: ChartData;
}

const ticksCallback = (x: string | number): string =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const scales: Scales = {
  'y-axis-1': {
    type: 'linear',
    position: 'left',
    display: true,
    beginAtZero: true,
    ticks: {
      callback: ticksCallback,
    },
  },
  'y-axis-2': {
    type: 'linear',
    position: 'right',
    display: true,
    beginAtZero: true,
    grid: {
      drawOnChartArea: false,
    },
    ticks: {
      callback: ticksCallback,
    },
  },
  'y-axis-3': {
    type: 'linear',
    position: 'right',
    display: true,
    beginAtZero: true,
    grid: {
      drawOnChartArea: false,
    },
    ticks: {
      callback: ticksCallback,
    },
  },
};

const ChartPanel = ({
  chartData: {
    lastUpdated,
    labels,
    facebook,
    yahoo,
    adform,
    tradedesk,
    yahooNative,
  },
}: ChartPanelProps): JSX.Element => {
  const addDataset = (
    label: string,
    data: StringOrNumber[],
    borderColor: string,
    backgroundColor: string,
    yAxisID: string,
    borderDash?: number[],
  ): {
    label: string;
    data: StringOrNumber[];
    borderColor: string;
    backgroundColor: string;
    yAxisID: string;
    borderDash: number[] | undefined;
  } => ({
    label,
    data,
    borderColor,
    backgroundColor,
    yAxisID,
    borderDash,
  });

  const data = {
    labels,
    datasets: [
      {
        label: 'Facebook Impressions',
        data: facebook.impressions,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(255, 99, 132)',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Facebook Clicks',
        data: facebook.clicks,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(255, 99, 132)',
        borderDash: [10, 5],
        yAxisID: 'y-axis-2',
      },
      {
        label: 'Facebook Count',
        data: facebook.count,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(255, 255, 255)',
        yAxisID: 'y-axis-3',
      },
      ...[
        addDataset(
          'Yahoo Display Impressions',
          yahoo.impressions,
          'rgb(44, 156, 40)',
          'rgb(44, 156, 40)',
          'y-axis-1',
        ),
        addDataset(
          'Yahoo Display Clicks',
          yahoo.clicks,
          'rgb(44, 156, 40)',
          'rgb(44, 156, 40)',
          'y-axis-2',
          [10, 5],
        ),
        addDataset(
          'Yahoo Display Count',
          yahoo.count,
          'rgb(44, 156, 40)',
          'rgb(255, 255, 255)',
          'y-axis-3',
        ),
      ],
      ...[
        addDataset(
          'Adform Impressions',
          adform.impressions,
          'rgb(255, 165, 0)',
          'rgb(255, 165, 0)',
          'y-axis-1',
        ),
        addDataset(
          'Adform Clicks',
          adform.clicks,
          'rgb(255, 165, 0)',
          'rgb(255, 165, 0)',
          'y-axis-2',
          [10, 5],
        ),
        addDataset(
          'Adform Count',
          adform.count,
          'rgb(255, 165, 0)',
          'rgb(255, 255, 255)',
          'y-axis-3',
        ),
      ],
      ...[
        addDataset(
          'Tradedesk Impressions',
          tradedesk.impressions,
          'rgb(168, 30, 53)',
          'rgb(168, 30, 53)',
          'y-axis-1',
        ),
        addDataset(
          'Tradedesk Clicks',
          tradedesk.clicks,
          'rgb(168, 30, 53)',
          'rgb(168, 30, 53)',
          'y-axis-2',
          [10, 5],
        ),
        addDataset(
          'Tradedesk Count',
          tradedesk.count,
          'rgb(168, 30, 53)',
          'rgb(255, 255, 255)',
          'y-axis-3',
        ),
      ],
      ...[
        addDataset(
          'Yahoo Native Impressions',
          yahooNative.impressions,
          'rgb(54, 162, 235)',
          'rgb(54, 162, 235)',
          'y-axis-1',
        ),
        addDataset(
          'Yahoo Native Clicks',
          yahooNative.clicks,
          'rgb(54, 162, 235)',
          'rgb(54, 162, 235)',
          'y-axis-2',
          [10, 5],
        ),
        addDataset(
          'Yahoo Native Count',
          yahooNative.count,
          'rgb(54, 162, 235)',
          'rgb(255, 255, 255)',
          'y-axis-3',
        ),
      ],
    ],
  };

  return (
    <Panel heading="3 week delivery data" showDivider={false}>
      <Stack gap="large">
        <Chart
          label="3 week delivery data chart"
          width="100%"
          height="25rem"
          legendPosition="right"
          data={data}
          scales={scales}
        />
        <P>{lastUpdated && `Last updated: ${formatDateTime(lastUpdated)}`}</P>
      </Stack>
    </Panel>
  );
};

export default ChartPanel;
