/* eslint-disable @typescript-eslint/no-explicit-any */
const debounce = <F extends (...args: any[]) => any>(
  func: F,
  timeout: number,
): ((...args: Parameters<F>) => void) => {
  let timeoutId: number | undefined;

  return (...args: Parameters<F>): void => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = window.setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

export default debounce;
