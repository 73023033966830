import { Alert } from '@rea-group/construct-kit-core';
import { ApiError } from '../API/fetch';

interface ErrorAlertProps {
  error: unknown;
}

const ErrorAlert = ({ error }: ErrorAlertProps): JSX.Element => {
  const message =
    error instanceof ApiError ? error.message : 'An error occurred.';

  return <Alert variant="warning">{message}</Alert>;
};

export default ErrorAlert;
