import { formatDateTime } from '../../utils/formatDate';
import Status from '../Status';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';

export interface OptimisationData {
  id: number;
  bookingId: string;
  action: string;
  system: string;
  status: string;
  note: string | null;
  updatedAt: string | null;
}

export interface BookingDataOptimisationsListPanelProps {
  data: OptimisationData[];
}

const BookingDataOptimisationsListPanel = ({
  data,
}: BookingDataOptimisationsListPanelProps): JSX.Element => (
  <BookingDataPanel heading="Optimisations List">
    <Table>
      <TableHeader>
        <TableHeaderColumn>Booking ID</TableHeaderColumn>
        <TableHeaderColumn>Action</TableHeaderColumn>
        <TableHeaderColumn>System</TableHeaderColumn>
        <TableHeaderColumn>Status</TableHeaderColumn>
        <TableHeaderColumn>Updated</TableHeaderColumn>
        <TableHeaderColumn>Note</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map(
            ({ id, bookingId, action, system, status, note, updatedAt }) => (
              <TableRow key={id}>
                <TableContentColumn>{bookingId}</TableContentColumn>
                <TableContentColumn>{action}</TableContentColumn>
                <TableContentColumn>{system}</TableContentColumn>
                <TableContentColumn>
                  <Status status={status} />
                </TableContentColumn>
                <TableContentColumn>
                  {formatDateTime(updatedAt)}
                </TableContentColumn>
                <TableContentColumn>{note}</TableContentColumn>
              </TableRow>
            ),
          )
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataOptimisationsListPanel;
