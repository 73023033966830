import { Line } from 'react-chartjs-2';
import { ChartData, LayoutPosition, ScaleOptions } from 'chart.js';
import styled from 'styled-components';

interface ChartContainerProps {
  width: string;
  height: string;
}

const ChartContainer = styled.div<ChartContainerProps>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export type Scales = Record<string, ScaleOptions<'linear'>>;

interface ChartProps {
  width: string;
  height: string;
  legendPosition: LayoutPosition;
  label: string;
  data: ChartData<'line', unknown, unknown>;
  scales: Scales;
}

const Chart = ({
  label,
  width,
  height,
  legendPosition,
  data,
  scales,
}: ChartProps): JSX.Element => {
  return (
    <ChartContainer width={width} height={height}>
      <Line
        options={{
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.4,
            },
          },
          plugins: {
            legend: {
              position: legendPosition,
              labels: {
                usePointStyle: true,
              },
            },
          },
          scales: scales,
        }}
        data={data}
        aria-label={label}
      />
    </ChartContainer>
  );
};

export default Chart;
