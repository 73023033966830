import {
  Carousel,
  FacebookLg,
  H1,
  H2,
  Inline,
  InstagramLg,
  Stack,
} from '@rea-group/construct-kit-core';
import { useQuery } from '@tanstack/react-query';
import { fetchJson } from '../API/fetch';
import ErrorAlert from '../components/ErrorAlert';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { PageLoadingSpinner } from '../components/LoadingSpinner';
import { isPresent } from '../utils/helpers';

interface CampaignImageUrls {
  instagramSingle: string | null;
  facebookSquares: string[];
  mediaMathNatives: string[];
}

const Image = styled.img`
  max-width: 100%;
`;

const InlineTitle = styled(Inline).attrs({
  gap: 'extraSmall',
  alignItems: 'center',
  justifyContent: 'start',
  grow: false,
})``;

const CampaignImages = ({
  title,
  imageUrls,
}: {
  title: JSX.Element;
  imageUrls: string[];
}): JSX.Element => {
  return (
    <Stack>
      {title}
      <Carousel insideGap="small" outsideGap="twoExtraSmall">
        {imageUrls.map((imageUrl) => (
          <Image src={imageUrl} key={imageUrl} />
        ))}
      </Carousel>
    </Stack>
  );
};

const CampaignImagesPreview = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const listingId = searchParams.get('listingId');

  const {
    isLoading,
    isError,
    error,
    data: campaignImagesData,
  } = useQuery({
    queryKey: ['campaignImages', listingId],
    queryFn: () =>
      fetchJson<CampaignImageUrls>(
        `/campaign-images?listingId=${listingId as string}`,
      ),
    enabled: !!listingId,
    staleTime: Infinity,
  });

  const isAllImageUrlsNotPresent =
    isPresent(campaignImagesData) &&
    campaignImagesData.instagramSingle == null &&
    campaignImagesData.facebookSquares.length === 0 &&
    campaignImagesData.mediaMathNatives.length === 0;

  return (
    <Stack gap="medium">
      <H1>Image Preview</H1>
      {isLoading && <PageLoadingSpinner />}
      {(isError || isAllImageUrlsNotPresent) && <ErrorAlert error={error} />}
      {campaignImagesData && (
        <Stack>
          {campaignImagesData.instagramSingle && (
            <CampaignImages
              title={
                <InlineTitle>
                  <InstagramLg />
                  <H2>Instagram</H2>
                </InlineTitle>
              }
              imageUrls={[campaignImagesData.instagramSingle]}
            />
          )}
          {campaignImagesData.facebookSquares.length > 0 && (
            <CampaignImages
              title={
                <InlineTitle>
                  <FacebookLg />
                  <H2>Facebook</H2>
                </InlineTitle>
              }
              imageUrls={campaignImagesData.facebookSquares}
            />
          )}
          {campaignImagesData.mediaMathNatives.length > 0 && (
            <CampaignImages
              title={<H2>Native</H2>}
              imageUrls={campaignImagesData.mediaMathNatives}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default CampaignImagesPreview;
