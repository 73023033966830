import { Divider, H1, Inline, Stack } from '@rea-group/construct-kit-core';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment/moment';
import { useState } from 'react';
import styled from 'styled-components';
import { fetchJson } from '../API/fetch';
import ErrorAlert from '../components/ErrorAlert';
import BookingCompleteEmailsModal from '../components/HomePage/BookingCompleteEmailsModal';
import BoostBookingsModal from '../components/HomePage/BoostBookingsModal';
import EmailsPanel from '../components/HomePage/EmailsPanel';
import LiveEmailsModal from '../components/HomePage/LiveEmailsModal';
import OptimizationPanel from '../components/HomePage/OptimizationPanel';
import { PageLoadingSpinner } from '../components/LoadingSpinner';
import QueryDatePicker from '../components/QueryDatePicker';
import { Nullable } from '../types/Nullable';
import { isPresent } from '../utils/helpers';

const StyledDivider = styled(Divider)`
  margin: 0;
`;

interface OptimisationGroupResponse {
  action: string;
  status: string;
  system: string;
  count: number;
  mostRecent: string | null;
}

interface OptimisationStatusCounts {
  waiting: number;
  processing: number;
  queued: number;
  done: number;
  error: number;
}

interface CountGroupByStatus {
  count: number;
  mostRecent: string | null;
  status: string;
}

export interface GetStatusMonitorResponse {
  optimisations: OptimisationGroupResponse[];
  optimisationStatusCounts: OptimisationStatusCounts;
  goLiveEmails: CountGroupByStatus[];
  bookingCompleteEmails: CountGroupByStatus[];
  boostBookings: CountGroupByStatus[];
}

interface OptimisationGroupData extends OptimisationGroupResponse {
  id: string;
}

interface StatusMonitorData {
  optimisations: OptimisationGroupData[];
  optimisationStatusCounts: OptimisationStatusCounts;
  goLiveEmails: CountGroupByStatus[];
  bookingCompleteEmails: CountGroupByStatus[];
  boostBookings: CountGroupByStatus[];
}

const getStatusMonitorData = async (
  queryDate: string,
): Promise<StatusMonitorData> => {
  const response = await fetchJson<GetStatusMonitorResponse>(
    `/status-monitor?date=${queryDate}`,
  );

  const optimisationsData = response.optimisations.map((optimisation) => {
    const { action, status, system } = optimisation;
    return {
      id: `${action}_${status}_${system}`,
      ...optimisation,
    };
  });

  return { ...response, optimisations: optimisationsData };
};

const StatusMonitor = (): JSX.Element => {
  const [queryDate, setQueryDate] = useState<Nullable<moment.Moment>>(moment());

  const {
    isLoading,
    isError,
    error,
    data: statusMonitorData,
  } = useQuery({
    queryKey: ['statusMonitorData', queryDate],
    queryFn: () => {
      if (isPresent(queryDate)) {
        return getStatusMonitorData(queryDate.format('YYYY-MM-DD'));
      }
    },
    enabled: isPresent(queryDate),
  });

  const handleClick = (date: Nullable<moment.Moment>): void => {
    setQueryDate(date);
  };

  const queryDateString = queryDate?.format('YYYY-MM-DD');

  return (
    <Stack gap="medium">
      <H1>Status Monitor</H1>
      <QueryDatePicker
        handleClick={handleClick}
        isLoading={isLoading}
        queryDate={queryDate}
        text={'results'}
      />
      <StyledDivider />
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {statusMonitorData && (
        <>
          <OptimizationPanel
            heading="Nimbus optimisations"
            optimizationData={statusMonitorData.optimisations}
            optimizationStatusCounts={
              statusMonitorData.optimisationStatusCounts
            }
            date={queryDateString}
          />
          <Inline gap="large" alignItems="flex-start">
            <EmailsPanel
              heading="Go live emails"
              buttonText="Open email list"
              emailsData={statusMonitorData.goLiveEmails}
              modalContents={<LiveEmailsModal date={queryDateString} />}
            />
            <EmailsPanel
              heading="Booking complete emails"
              buttonText="Open complete email list"
              emailsData={statusMonitorData.bookingCompleteEmails}
              modalContents={
                <BookingCompleteEmailsModal date={queryDateString} />
              }
            />
            <EmailsPanel
              heading="Boost bookings"
              buttonText="Open boost booking list"
              emailsData={statusMonitorData.boostBookings}
              modalContents={<BoostBookingsModal date={queryDateString} />}
            />
          </Inline>
        </>
      )}
    </Stack>
  );
};

export default StatusMonitor;
