import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { fetchJson } from '../../API/fetch';
import { isPresent } from '../../utils/helpers';
import ErrorAlert from '../ErrorAlert';
import FBAudienceCountsTable from './FBAudienceCountsTable';
import FBAudienceDetailsModal from './FBAudienceDetailsModal';
import { FacebookAudienceCountsResponse } from './types';
import { PageLoadingSpinner } from '../LoadingSpinner';

const FBAudienceCountNimbus = (): JSX.Element => {
  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['fbAudienceCountNimbus'],
    queryFn: () =>
      fetchJson<FacebookAudienceCountsResponse>('/facebook-audience-counts'),
  });

  const [detailsAccountId, setDetailsAccountId] = useState<string>();

  return (
    <>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {data && (
        <FBAudienceCountsTable
          data={data.audienceCounts}
          onAccountIdClick={setDetailsAccountId}
        />
      )}
      <FBAudienceDetailsModal
        accountId={detailsAccountId}
        opened={isPresent(detailsAccountId)}
        onRequestClose={() => setDetailsAccountId(undefined)}
      />
    </>
  );
};

export default FBAudienceCountNimbus;
