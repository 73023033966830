import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { isPresent } from './helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface FeatureToggles {}

const defaultToggles: FeatureToggles = {};

const parseToggleValue = (
  toggle: keyof FeatureToggles,
  value: string | null,
): Partial<FeatureToggles> | undefined =>
  isPresent(value) ? { [toggle]: value === 'true' } : undefined;

const parseQueryToggles = (
  params: URLSearchParams,
): Partial<FeatureToggles> => ({});

const parseSessionStorageToggles = (
  toggles: string | null,
): Partial<FeatureToggles> | undefined => {
  if (!isPresent(toggles)) {
    return undefined;
  }
  try {
    return JSON.parse(toggles) as Partial<FeatureToggles>;
  } catch {
    return undefined;
  }
};

const FeatureTogglesContext = createContext(defaultToggles);

export const FeatureToggleProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [featureToggles] = useState<FeatureToggles>(() => {
    const queryToggles = parseQueryToggles(
      new URLSearchParams(window.location.search),
    );

    const sessionStorageToggles = parseSessionStorageToggles(
      sessionStorage.getItem('toggles'),
    );

    return {
      ...defaultToggles,
      ...sessionStorageToggles,
      ...queryToggles,
    };
  });

  useEffect(() => {
    sessionStorage.setItem('toggles', JSON.stringify(featureToggles));
  }, [featureToggles]);

  return (
    <FeatureTogglesContext.Provider value={featureToggles}>
      {children}
    </FeatureTogglesContext.Provider>
  );
};

export const useFeatureToggles = (): FeatureToggles =>
  useContext(FeatureTogglesContext);
