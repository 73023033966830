import { Link } from '@rea-group/construct-kit-core';
import { ValidationErrorBooking } from '../../pages/UploadBookingCampaign';
import BookingsModal from './BookingsModal';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';

interface MatchingPackageErrorModalProps {
  bookings: ValidationErrorBooking[];
}

const MatchingPackageErrorModal = ({
  bookings,
}: MatchingPackageErrorModalProps): JSX.Element => {
  return (
    <BookingsModal heading="Unsuccessful Package Match">
      <Table>
        <TableHeader>
          <TableHeaderColumn>Booking ID</TableHeaderColumn>
          <TableHeaderColumn>Listing ID</TableHeaderColumn>
          <TableHeaderColumn>Package ID</TableHeaderColumn>
          <TableHeaderColumn>Booking Period</TableHeaderColumn>
          <TableHeaderColumn>Impressions</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {bookings.length === 0 ? (
            <EmptyTableBody />
          ) : (
            bookings.map(
              ({
                rowNumber,
                bookingId,
                listingId,
                packageId,
                bookingPeriod,
                impressions,
              }) => (
                <TableRow key={rowNumber}>
                  <TableContentColumn>{bookingId}</TableContentColumn>
                  <TableContentColumn>
                    <Link
                      href={`https://www.realestate.com.au/${listingId}`}
                      target="_blank"
                    >
                      {listingId}
                    </Link>
                  </TableContentColumn>
                  <TableContentColumn>{packageId}</TableContentColumn>
                  <TableContentColumn>{bookingPeriod}</TableContentColumn>
                  <TableContentColumn>
                    {impressions.toLocaleString()}
                  </TableContentColumn>
                </TableRow>
              ),
            )
          )}
        </TableBody>
      </Table>
    </BookingsModal>
  );
};

export default MatchingPackageErrorModal;
