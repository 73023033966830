import { isPresent } from './helpers';

const formatPrice = (price: number | null): string =>
  isPresent(price)
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      }).format(price)
    : '';

export default formatPrice;
