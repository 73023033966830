import { H1, Stack } from '@rea-group/construct-kit-core';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useState } from 'react';
import { fetchJson } from '../API/fetch';
import ErrorAlert from '../components/ErrorAlert';
import { PageLoadingSpinner } from '../components/LoadingSpinner';
import QueryDatePicker from '../components/QueryDatePicker';
import { Nullable } from '../types/Nullable';
import { isPresent } from '../utils/helpers';
import { Job, JobsTable } from './JobQueue';

interface JobHistoryResponse {
  jobs: Job[];
}

const JobHistory = (): JSX.Element => {
  const [queryDate, setQueryDate] = useState<Nullable<moment.Moment>>(moment());

  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['jobsHistoryData', queryDate],
    queryFn: () => {
      if (isPresent(queryDate)) {
        return fetchJson<JobHistoryResponse>(
          `/jobs/history?processDate=${queryDate.format('YYYY-MM-DD')}`,
        );
      }
    },
    enabled: isPresent(queryDate),
  });

  const handleClick = (date: Nullable<moment.Moment>): void => {
    setQueryDate(date);
  };

  const { jobs } = data ?? {};

  return (
    <Stack gap="threeExtraLarge">
      <H1>Job History</H1>
      <QueryDatePicker
        handleClick={handleClick}
        isLoading={isLoading}
        queryDate={queryDate}
        text={'history'}
      />
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {jobs && <JobsTable jobs={jobs} />}
    </Stack>
  );
};

export default JobHistory;
