import {
  Button,
  Dialog,
  H2,
  Inline,
  Modal,
  Stack,
  TextInput,
} from '@rea-group/construct-kit-core';
import { useEffect, useRef, useState } from 'react';
import { getAppRoot } from '../../utils/getAppRoot';
import ConfirmModal from '../ConfirmModal';
import { useMutation } from '@tanstack/react-query';
import { isPresent } from '../../utils/helpers';
import { isEmpty, omit } from 'lodash';
import { fetchJson } from '../../API/fetch';
import {
  BookingTrackerColumnName,
  BookingTrackerColumnsPreset,
  BookingTrackerFilters,
} from '../../pages/Tracker';
import ErrorAlert from '../ErrorAlert';
import { ButtonLoadingSpinner } from '../LoadingSpinner';

const createPreset = (
  presetData: Omit<BookingTrackerColumnsPreset, 'id'>,
): Promise<BookingTrackerColumnsPreset> =>
  fetchJson<BookingTrackerColumnsPreset>('/booking-trackers/columns-presets', {
    method: 'POST',
    body: JSON.stringify(presetData),
  });

const updatePreset = (
  presetData: BookingTrackerColumnsPreset,
): Promise<BookingTrackerColumnsPreset> =>
  fetchJson<BookingTrackerColumnsPreset>(
    `/booking-trackers/columns-presets/${presetData.id}`,
    {
      method: 'PUT',
      body: JSON.stringify(omit(presetData, 'id')),
    },
  );

const findDuplicatedPresetId = (
  presetName: string,
  existingPresets: BookingTrackerColumnsPreset[],
): number | undefined =>
  existingPresets.find(({ name }) => name === presetName)?.id;

export interface SavePresetModalParams {
  isOpen: boolean;
  displayedColumns: BookingTrackerColumnName[];
  filters: BookingTrackerFilters;
  existingPresets: BookingTrackerColumnsPreset[];
  onSuccess: (savedPreset: BookingTrackerColumnsPreset) => void;
  onCancel: () => void;
  onClose: () => void;
}

const SavePresetModal = ({
  isOpen,
  displayedColumns,
  filters,
  existingPresets,
  onSuccess,
  onCancel,
  onClose,
}: SavePresetModalParams): JSX.Element => {
  const createPresetMutation = useMutation({
    mutationFn: createPreset,
    onSuccess: (savedPreset) => {
      onSuccess(savedPreset);
      onClose();
    },
  });

  const updatePresetMutation = useMutation({
    mutationFn: updatePreset,
    onSuccess: (savedPreset) => {
      onSuccess(savedPreset);
      onClose();
    },
  });

  useEffect(() => {
    setPresetName('');
  }, [isOpen]);

  const [presetName, setPresetName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [
    isOverwritePresetConfirmModalOpen,
    setIsOverwritePresetConfirmModalOpen,
  ] = useState(false);
  const duplicatedPresetId = useRef<number>();

  const filtersWithoutDate = omit(filters, ['startDate', 'endDate']);
  const filtersToSave = !isEmpty(filtersWithoutDate)
    ? filtersWithoutDate
    : null;

  return (
    <Modal opened={isOpen} getAppElement={getAppRoot} onRequestClose={onClose}>
      <Dialog onClose={onClose}>
        <Stack gap="large" inset="0 medium medium">
          <H2>Save current displayed columns and filters as a preset</H2>
          {(createPresetMutation.isError || updatePresetMutation.isError) && (
            <ErrorAlert
              error={createPresetMutation.error || updatePresetMutation.error}
            />
          )}
          <TextInput
            name="preset"
            label="Save current displayed columns and filters as a preset"
            placeholder="Please enter a name for the preset"
            onChange={(e) => setPresetName(e.target.value.trim())}
            onBlur={() =>
              presetName.trim().length === 0
                ? setErrorMessage('The name should not be empty.')
                : setErrorMessage(undefined)
            }
            errorMessage={errorMessage}
            hideLabel
          />
          <Inline gap="medium" justifyContent="space-round">
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                duplicatedPresetId.current = findDuplicatedPresetId(
                  presetName.trim(),
                  existingPresets,
                );
                if (isPresent(duplicatedPresetId.current)) {
                  setIsOverwritePresetConfirmModalOpen(true);
                  return;
                }

                createPresetMutation.mutate({
                  name: presetName.trim(),
                  displayedColumns,
                  filters: filtersToSave,
                });
              }}
              disabled={
                presetName.trim().length === 0 ||
                createPresetMutation.isPending ||
                updatePresetMutation.isPending
              }
              icon={
                createPresetMutation.isPending ||
                updatePresetMutation.isPending ? (
                  <ButtonLoadingSpinner />
                ) : undefined
              }
              iconPlacement="right"
            >
              Save
            </Button>
          </Inline>
        </Stack>
      </Dialog>
      <ConfirmModal
        headerText={`The preset "${presetName}" already exists. Do you want to overwrite it?`}
        okButtonText="Overwrite"
        isOpen={isOverwritePresetConfirmModalOpen}
        isError={false}
        isOkButtonDisabled={false}
        onOk={() => {
          updatePresetMutation.mutate({
            id: duplicatedPresetId.current!,
            name: presetName.trim(),
            displayedColumns,
            filters: filtersToSave,
          });
          setIsOverwritePresetConfirmModalOpen(false);
        }}
        onCancel={() => setIsOverwritePresetConfirmModalOpen(false)}
        onClose={() => setIsOverwritePresetConfirmModalOpen(false)}
        error=""
      />
    </Modal>
  );
};

export default SavePresetModal;
