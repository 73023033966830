import { Outlet } from 'react-router-dom';
import { Stack, spacingHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import Header from './Header';

const OutletContainer = styled.div`
  padding: ${spacingHelper('0 threeExtraLarge threeExtraLarge')};
`;

const Layout = (): JSX.Element => {
  return (
    <Stack gap="threeExtraLarge">
      <Header />
      <OutletContainer>
        <Outlet />
      </OutletContainer>
    </Stack>
  );
};

export default Layout;
