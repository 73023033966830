import { useState, ReactNode } from 'react';
import styled from 'styled-components';
import {
  Card,
  Stack,
  H3,
  H4,
  Divider,
  spacingHelper,
  Button,
  Modal,
  Dialog,
  ArrowRightMd,
} from '@rea-group/construct-kit-core';
import { getAppRoot } from '../../utils/getAppRoot';

interface PanelProps {
  children: ReactNode;
  heading: string;
  subHeading?: ReactNode;
  buttonText?: string;
  modalContents?: ReactNode;
  showDivider?: boolean;
}

const StyledDivider = styled(Divider)`
  margin: ${spacingHelper('0 0 medium')};
`;

const StyledDialog = styled(Dialog)`
  min-width: 75rem;
  min-height: 30rem;
  padding: ${spacingHelper('0 medium medium')};
`;

const Panel = ({
  children,
  heading,
  subHeading,
  buttonText,
  modalContents,
  showDivider = true,
}: PanelProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Card>
      <Stack inset="large medium" gap="medium">
        <Stack gap="0">
          <H3>{heading}</H3>
          {subHeading && <H4 variant="body01">{subHeading}</H4>}
        </Stack>
        {showDivider && <StyledDivider />}
        {children}
        {buttonText && (
          <>
            <Button
              variant="outline"
              sizeVariant="medium"
              fullWidth={true}
              icon={<ArrowRightMd />}
              iconPlacement="right"
              onClick={() => setIsModalOpen(true)}
            >
              {buttonText}
            </Button>
            <Modal
              opened={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              getAppElement={getAppRoot}
            >
              <StyledDialog
                onClose={() => setIsModalOpen(false)}
                maxWidth="100rem"
              >
                {modalContents}
              </StyledDialog>
            </Modal>
          </>
        )}
      </Stack>
    </Card>
  );
};

export default Panel;
