import {
  Button,
  Inline,
  P,
  Stack,
  paletteHelper,
} from '@rea-group/construct-kit-core';
import moment from 'moment/moment';
import { useState } from 'react';
import styled from 'styled-components';
import { Nullable } from '../types/Nullable';
import { isPresent } from '../utils/helpers';
import { ButtonLoadingSpinner } from './LoadingSpinner';
import SingleDatePicker from './calendar/SingleDatePicker';

const DatePickerContainer = styled.div`
  width: 40%;
`;

const StyledP = styled(P)`
  color: ${paletteHelper('textSecondary')};
`;

interface Props {
  handleClick: (date: Nullable<moment.Moment>) => void;
  isLoading: boolean;
  queryDate: Nullable<moment.Moment>;
  text: string;
}

const QueryDatePicker = ({
  handleClick,
  isLoading,
  queryDate,
  text,
}: Props): JSX.Element => {
  const [date, setDate] = useState<Nullable<moment.Moment>>(queryDate);

  return (
    <DatePickerContainer>
      <Stack gap="medium">
        <Stack gap="twoExtraSmall">
          <P variant="subtitle01">Enter date</P>
          <Inline gap="large" grow={false}>
            <SingleDatePicker
              date={date}
              placeHolder={'please select a date'}
              onDateChange={setDate}
            />
            <Button
              sizeVariant="medium"
              onClick={() => isPresent(date) && handleClick(date)}
              disabled={!isPresent(date) || isLoading}
              iconPlacement="right"
              icon={isLoading ? <ButtonLoadingSpinner /> : undefined}
            >
              {isLoading ? 'Loading' : `Show ${text}`}
            </Button>
          </Inline>
        </Stack>
        {isPresent(queryDate) && (
          <StyledP>
            Showing {text} for {queryDate.format('dddd DD/MM/YYYY')}
          </StyledP>
        )}
      </Stack>
    </DatePickerContainer>
  );
};

export default QueryDatePicker;
