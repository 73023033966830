import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { postVoid } from '../API/fetch';
import ConfirmModal from './ConfirmModal';
import { UpdateBooking } from './ProcessBookingCampaignPanel';

const processCampaign = (bookingsToUpdate: UpdateBooking[]): Promise<void> => {
  return postVoid(`/jobs/process`, {
    body: JSON.stringify({ bookingsToUpdate }),
  });
};

interface ProcessBookingCampaignConfirmModelProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  bookingsToUpdate: UpdateBooking[];
}

const ProcessBookingCampaignConfirmModel = ({
  isOpen,
  onClose,
  onCancel,
  bookingsToUpdate,
}: ProcessBookingCampaignConfirmModelProps): JSX.Element => {
  const navigate = useNavigate();

  const processMutation = useMutation({
    mutationFn: processCampaign,
    onSuccess: () => {
      navigate('/job-queue');
    },
  });

  return (
    <ConfirmModal
      headerText="Do you want to process the campaign?"
      okButtonText="Process now"
      isOpen={isOpen}
      isError={processMutation.isError}
      error={processMutation.error}
      isOkButtonDisabled={processMutation.isPending}
      onClose={() => {
        processMutation.reset();
        onClose();
      }}
      onOk={() => {
        processMutation.mutate(bookingsToUpdate);
      }}
      onCancel={() => {
        processMutation.reset();
        onCancel();
      }}
    />
  );
};

export default ProcessBookingCampaignConfirmModel;
