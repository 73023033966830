import { TargetingModFormValues } from '../../components/targetingMods/TargetingModForm';
import { emptyIfNotPresent, isPresent } from '../../utils/helpers';
import { TargetingMod } from './types';

export const fromTargetingModResponse = ({
  name,
  postcodes,
  state,
  propertyTypes,
  priceMin,
  priceMax,
  segment,
}: TargetingMod): TargetingModFormValues => ({
  name,
  postcodes: emptyIfNotPresent(postcodes),
  propertyTypes: emptyIfNotPresent(propertyTypes),
  priceMin: emptyIfNotPresent(priceMin),
  priceMax: emptyIfNotPresent(priceMax),
  state: emptyIfNotPresent(state),
  segment: isPresent(segment) ? segment : 'A',
});
