import { formatInTimeZone } from 'date-fns-tz';
import { isPresent } from './helpers';

export const formatDateTime = (date: string | null): string =>
  isPresent(date)
    ? formatInTimeZone(date, 'Australia/Sydney', 'd MMM yyyy h:mm aaa')
    : '';

export const formatDate = (date: string | null): string =>
  isPresent(date)
    ? formatInTimeZone(date, 'Australia/Sydney', 'd MMM yyyy')
    : '';

export const formatAustralianDate = (date: string | null): string =>
  isPresent(date)
    ? formatInTimeZone(date, 'Australia/Sydney', 'dd/MM/yyyy')
    : '';
