import { formatDateTime } from '../../utils/formatDate';
import ExternalLink from '../ExternalLink';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import BookingDataPanel from './BookingDataPanel';
import ImagesLink, { ImagesJobLink } from '../ImagesLink';

export interface CompleteJobData {
  id: number;
  endpoint: string;
  status: string;
  listingId: number | null;
  mediamathLink: string | null;
  nativeLink: string | null;
  imagesLink: ImagesJobLink;
  facebookLink: string | null;
  yahooLink: string | null;
  adformLink: string | null;
  tradedeskLink: string | null;
  yahooNativeLink: string | null;
  bookingId: string;
  processId: number;
  attemptCount: number | null;
  processDate: string;
}

export interface BookingDataCompletedJobsListPanelProps {
  data: CompleteJobData[];
}

type BookingDataCompletedJobsColumnName = keyof CompleteJobData;

export const columnLabels: Record<BookingDataCompletedJobsColumnName, string> =
  {
    id: 'ID',
    endpoint: 'Endpoint',
    status: 'Status',
    listingId: 'Listing ID',
    mediamathLink: 'Mediamath link',
    nativeLink: 'Native link',
    imagesLink: 'Images link',
    facebookLink: 'Facebook link',
    yahooLink: 'Yahoo link',
    adformLink: 'Adform link',
    tradedeskLink: 'Tradedesk link',
    yahooNativeLink: 'Yahoo native link',
    bookingId: 'Booking ID',
    processId: 'Process ID',
    attemptCount: 'Attempt count',
    processDate: 'Process date',
  };

const columnNames = Object.keys(
  columnLabels,
) as BookingDataCompletedJobsColumnName[];

const createCellRenderers = ({
  id,
  endpoint,
  status,
  listingId,
  mediamathLink,
  nativeLink,
  imagesLink,
  facebookLink,
  yahooLink,
  adformLink,
  tradedeskLink,
  yahooNativeLink,
  bookingId,
  processId,
  attemptCount,
  processDate,
}: CompleteJobData): Record<
  BookingDataCompletedJobsColumnName,
  () => JSX.Element | string | number | boolean | null
> => ({
  id: () => id,
  endpoint: () => endpoint,
  status: () => status,
  listingId: () => listingId,
  mediamathLink: () =>
    mediamathLink && (
      <ExternalLink href={mediamathLink}>Mediamath link</ExternalLink>
    ),
  nativeLink: () =>
    nativeLink && <ExternalLink href={nativeLink}>Native link</ExternalLink>,
  imagesLink: () =>
    imagesLink &&
    listingId && (
      <ImagesLink link={imagesLink} listingId={listingId}>
        Images link
      </ImagesLink>
    ),
  facebookLink: () =>
    facebookLink && (
      <ExternalLink href={facebookLink}>Facebook link</ExternalLink>
    ),
  yahooLink: () =>
    yahooLink && <ExternalLink href={yahooLink}>Yahoo link</ExternalLink>,
  adformLink: () =>
    adformLink && <ExternalLink href={adformLink}>Adform link</ExternalLink>,
  tradedeskLink: () =>
    tradedeskLink && (
      <ExternalLink href={tradedeskLink}>Tradedesk link</ExternalLink>
    ),
  yahooNativeLink: () =>
    yahooNativeLink && (
      <ExternalLink href={yahooNativeLink}>Yahoo native link</ExternalLink>
    ),
  bookingId: () => bookingId,
  processId: () => processId,
  attemptCount: () => attemptCount,
  processDate: () => formatDateTime(processDate),
});

const BookingDataCompletedJobsListPanel = ({
  data,
}: BookingDataCompletedJobsListPanelProps): JSX.Element => (
  <BookingDataPanel heading="Jobs History">
    <Table>
      <TableHeader>
        {columnNames.map((columnName) => (
          <TableHeaderColumn key={`th-${columnName}`}>
            {columnLabels[columnName]}
          </TableHeaderColumn>
        ))}
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <EmptyTableBody />
        ) : (
          data.map((dataRow) => {
            // eslint-disable-next-line testing-library/render-result-naming-convention
            const cellRenderers = createCellRenderers(dataRow);

            return (
              <TableRow key={`tr-${dataRow.id}`}>
                {columnNames.map((columnName) => (
                  <TableContentColumn key={`td-${columnName}`}>
                    {cellRenderers[columnName]()}
                  </TableContentColumn>
                ))}
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  </BookingDataPanel>
);

export default BookingDataCompletedJobsListPanel;
